import { FC, PropsWithChildren, useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

interface NestedListProps extends PropsWithChildren {
  label: string;
  sidebarExpanded: boolean;
  icon: React.ElementType | string;
  nested?: boolean;
}

const NestedList: FC<NestedListProps> = ({
  children,
  label,
  sidebarExpanded,
  icon: Icon,
  nested
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={handleClick}>
          {typeof Icon !== 'string' && (
            <ListItemIcon
              sx={{
                pl: nested && sidebarExpanded ? 2 : 'initial',
                '& svg': {
                  width: 22,
                  height: 22
                }
              }}
            >
              <Icon />
            </ListItemIcon>
          )}
          <ListItemText primary={label} sx={{ opacity: sidebarExpanded ? 1 : 0 }} />
          {sidebarExpanded ? open ? <ExpandLess /> : <ExpandMore /> : null}
        </ListItemButton>
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className="nested-list">
          {children}
        </List>
      </Collapse>
    </>
  );
};

export default NestedList;
