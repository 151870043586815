import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUser } from 'interfaces/userInterface';
import { Storage } from 'utils/Storage';

interface AuthState {
  user?: IUser;
  token?: string;
}

const initialState = {
  token: Storage.getAccessToken(),
  user: Storage.getCurrentUser()
} as AuthState;

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, { payload }: PayloadAction<AuthState>) => {
      if (payload.user) {
        state.user = payload.user;
      }

      if (payload.token) {
        state.token = payload.token;
      }

      Storage.setCredentials(payload.user, payload.token);
    },
    logout: state => {
      state.user = undefined;
      state.token = undefined;
      Storage.removeCredentials();
    }
  }
});

export const { setCredentials, logout } = AuthSlice.actions;

export default AuthSlice.reducer;
