import { RegisterUserType, SuccessRegisterResult } from 'interfaces/authInterface';
import { PopulatedClip, PopulatedBoost } from 'interfaces/episodeInterface';
import { PodcastLatestEpisodeType } from 'interfaces/podcastInterface';
import { GetAllResponse, Params } from 'interfaces/general';
import {
  EmailBodyType,
  EnhancedUser,
  INotification,
  IUser,
  IUserWallet,
  UserFormType,
  UserMetaDataType,
  UsersWalletInfoType
} from 'interfaces/userInterface';
import { IProducer } from 'interfaces/producerInterface';
import { setCredentials } from 'redux/slices/authSlice';
import { queryTags, TagTypes } from 'utils/queryTags';
import { baseApi, CrudController, onSuccessQuery } from './baseApi';
import { PartialUpdateType } from './types';

const BASE_PATH = `/${TagTypes.USERS}` as const;

export const userApi = baseApi.injectEndpoints({
  endpoints: builder => {
    const crudCtrl = new CrudController(builder, BASE_PATH, TagTypes.USERS, 'User');

    return {
      getUsers: crudCtrl.getDocumentsQuery<IUser>(),
      searchUsers: crudCtrl.getDocumentsQuery<EnhancedUser>('search'),
      updateUser: crudCtrl.updateDocumentMutation<PartialUpdateType<IUser>>(),
      getAdminValue4Value: builder.query<IUserWallet, null>({
        query() {
          return {
            url: `${BASE_PATH}/admin/value4value`
          };
        },
        providesTags: queryTags.users.single('admin-value'),
        transformResponse: (response: { data: IUserWallet }) => response.data
      }),
      getUsersWalletInfo: builder.query<UsersWalletInfoType, string[]>({
        query(userIDs) {
          return {
            url: `${BASE_PATH}/wallet`,
            params: {
              users: userIDs.join(',')
            }
          };
        },
        providesTags: queryTags.users.list(),
        transformResponse: (response: { data: UsersWalletInfoType }) => response.data
      }),
      getAdminWalletBalance: builder.query<number, null>({
        query() {
          return {
            url: `${BASE_PATH}/admin/balance`
          };
        },
        providesTags: queryTags.users.single('admin-sats'),
        transformResponse: (response: { data: number }) => response.data
      }),
      getCurrentUserPublisherStatus: builder.query<
        { active: boolean; publisher?: IProducer },
        undefined
      >({
        query() {
          return {
            url: `${BASE_PATH}/me/able-to-add-producer`
          };
        },
        providesTags: queryTags.producers.list(),
        transformResponse: (response: { data: { active: boolean; publisher?: IProducer } }) =>
          response.data
      }),
      getUserMetaData: builder.query<UserMetaDataType, { userId: string; podcastId?: string }>({
        query({ userId, podcastId }) {
          return {
            url: `${BASE_PATH}/${userId}/meta${podcastId ? `?podcast=${podcastId}` : ''}`
          };
        },
        providesTags: (_, __, { userId }) => queryTags.users.single(userId),
        transformResponse: (response: { data: { meta: UserMetaDataType } }) => response.data.meta
      }),
      getUserBoosts: builder.query<GetAllResponse<PopulatedBoost>, Params & { userId: string }>({
        query({ userId, ...params }) {
          return {
            url: `${BASE_PATH}/${userId}/boosts`,
            params
          };
        },
        providesTags: queryTags.boosts.list()
      }),
      getUserClips: builder.query<GetAllResponse<PopulatedClip>, Params & { userId: string }>({
        query({ userId, ...params }) {
          return {
            url: `${BASE_PATH}/${userId}/clips`,
            params
          };
        },
        providesTags: queryTags.clips.list()
      }),
      getCurrentUser: builder.query<IUser, null>({
        query() {
          return {
            url: `${BASE_PATH}/me`
          };
        },
        providesTags: queryTags.users.single('me'),
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(setCredentials({ user: data }));
          } catch (__) {}
        }
      }),
      getAlbyKeys: builder.query<
        {
          pubkey: string;
          customData: [
            {
              customKey: string;
              customValue: string;
            }
          ];
        },
        string
      >({
        query(username) {
          return {
            url: `https://getalby.com/.well-known/keysend/${username}`
          };
        }
      }),
      updateCurrentUser: builder.mutation<IUser, Partial<UserFormType>>({
        query: data => ({
          url: `${BASE_PATH}/me`,
          method: 'PATCH',
          body: data
        }),
        invalidatesTags: queryTags.users.list(),
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(setCredentials({ user: data }));
            onSuccessQuery('Data', 'updated');
          } catch (__) {}
        }
      }),
      registerUser: builder.mutation<SuccessRegisterResult, RegisterUserType>({
        query: data => ({
          url: BASE_PATH,
          method: 'POST',
          body: data
        }),
        invalidatesTags: queryTags.users.list()
      }),
      recoverUser: builder.mutation<null, string>({
        query: userId => ({
          url: `${BASE_PATH}/${userId}/recover`,
          method: 'PATCH'
        }),
        invalidatesTags: queryTags.users.list()
      }),
      sendEmail: builder.mutation<null, EmailBodyType>({
        query: ({ userId, ...data }) => ({
          url: `${BASE_PATH}/${userId}/email`,
          method: 'POST',
          body: data
        }),
        async onQueryStarted(_, { queryFulfilled }) {
          try {
            await queryFulfilled;
            onSuccessQuery('Email', 'sent');
          } catch (__) {}
        }
      }),
      getMyFans: builder.query<GetAllResponse<EnhancedUser>, Params & { podcastId: string }>({
        query({ podcastId, ...params }) {
          return {
            url: `${BASE_PATH}/me/podcasts/${podcastId}/fans`,
            params
          };
        },
        providesTags: queryTags.users.list()
      }),
      getMyNotifications: builder.query<GetAllResponse<INotification>, null>({
        query() {
          return {
            url: `${BASE_PATH}/me/notifications?dashboard=1&seen=false`
          };
        },
        providesTags: queryTags.notifications.list()
      }),
      clearMyNotifications: builder.mutation({
        query: () => ({
          url: `${BASE_PATH}/me/notifications/clear?dashboard=1`,
          method: 'PATCH'
        }),
        invalidatesTags: queryTags.notifications.list()
      }),
      updateMyNotification: builder.mutation<null, { id: string; seen: boolean }>({
        query: ({ id, seen }) => ({
          url: `/notifications/${id}`,
          method: 'PATCH',
          body: { seen }
        }),
        invalidatesTags: queryTags.notifications.list()
      }),
      getUserLibrary: builder.query<
        GetAllResponse<PodcastLatestEpisodeType>,
        Params & { userId: string; podcastId: string }
      >({
        query({ userId, podcastId, ...params }) {
          return {
            url: `${BASE_PATH}/${userId}/activities/library`,
            params: {
              played: true,
              stats: false,
              podcast: podcastId,
              ...params
            }
          };
        },
        providesTags: queryTags.users.list()
      })
    };
  },
  overrideExisting: false
});

export const {
  useGetUsersQuery,
  useGetCurrentUserQuery,
  useUpdateCurrentUserMutation,
  useRegisterUserMutation,
  useRecoverUserMutation,
  useSendEmailMutation,
  useSearchUsersQuery,
  useGetMyFansQuery,
  useGetUserLibraryQuery,
  useUpdateUserMutation,
  useGetUserBoostsQuery,
  useGetUserClipsQuery,
  useGetUsersWalletInfoQuery,
  useGetAdminWalletBalanceQuery,
  useGetAdminValue4ValueQuery,
  useLazyGetAlbyKeysQuery,
  useGetMyNotificationsQuery,
  useGetUserMetaDataQuery,
  useUpdateMyNotificationMutation,
  useClearMyNotificationsMutation,
  useGetCurrentUserPublisherStatusQuery
} = userApi;
