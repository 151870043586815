import { PartialPodcast } from './episodeInterface';
import { MongoDoc } from './general';
import { BriefUser } from './userInterface';

export enum ClaimStatus {
  EMAILED = 'emailed',
  VERIFIED = 'verified',
  REJECTED = 'rejected',
  TRANSFERRED = 'transferred'
}

export interface IClaim extends MongoDoc {
  user: BriefUser;
  podcast: PartialPodcast;
  attempts: number;
  status: ClaimStatus;
  virtualSats: number;
}
