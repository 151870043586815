import { FC } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CustomAvatar from 'components/CustomAvatar';
import { useAppSelector } from 'hooks/useAppSelector';
import { ReactComponent as Logo } from './truefans-dark.svg';
import Notifications from './Notifications';
import { useStyles } from './styles/main';

const MainHeader: FC = () => {
  const { classes } = useStyles();
  const { user } = useAppSelector(state => state.auth);

  return (
    <header className={classes.header}>
      <Stack direction="row">
        <Stack
          mr="auto"
          alignItems="flex-start"
          component="a"
          target="_blank"
          href={process.env.REACT_APP_WEBSITE_HOST}
        >
          <Logo width={197} height={50} />
        </Stack>

        {user && (
          <Stack direction="row" spacing={3} alignItems="center">
            <Notifications userRole={user.role} />

            <Typography className="medium">
              Logged in :&nbsp;
              <strong>{user.name || user.username}</strong>
            </Typography>

            <CustomAvatar username={user.username} src={user.avatar} />
          </Stack>
        )}
      </Stack>
    </header>
  );
};

export default MainHeader;
