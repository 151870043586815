import HomeIcon from '@mui/icons-material/Home';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import PeopleIcon from '@mui/icons-material/People';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import SubjectIcon from '@mui/icons-material/Subject';
import ForumIcon from '@mui/icons-material/Forum';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';

import { Pages } from 'interfaces/general';
import { lazyPreload } from 'utils/lazyPreload';
import type { AppRoutesType, IAppRoute } from 'interfaces/routeInterface';
import { UserRole } from 'interfaces/userInterface';
import { ReactComponent as FactoryIcon } from 'assets/svg/building.svg';
import { ReactComponent as ProducerIcon } from 'assets/svg/producers.svg';

const HomePage = lazyPreload(() => import('pages/home'));
const PodcastsPage = lazyPreload(() => import('pages/podcasts'));
const ProducersPage = lazyPreload(() => import('pages/producers'));
const ArtistsPage = lazyPreload(() => import('pages/artists'));
const PodcastPage = lazyPreload(() => import('pages/podcast'));
const EpisodePage = lazyPreload(() => import('pages/episode'));
const UsersPage = lazyPreload(() => import('pages/users'));
const FeedbackPage = lazyPreload(() => import('pages/feedback'));
const PostsPage = lazyPreload(() => import('pages/posts'));
const HostsPage = lazyPreload(() => import('pages/hosts'));
const SuggestionsPage = lazyPreload(() => import('pages/suggestions'));
const ServerLogsPage = lazyPreload(() => import('pages/server-logs'));
const CreatorsDashboardPage = lazyPreload(() => import('pages/creators-dashboard'));

type RoutesType = IAppRoute & {
  children?: IAppRoute[];
};

export const ROUTES: RoutesType[] = [
  // HOME
  {
    path: Pages.HOME,
    component: HomePage,
    label: 'Home',
    icon: HomeIcon,
    allowedRoles: [UserRole.ADMIN]
  },
  // Creators dashboard
  {
    path: Pages.CREATOR_DASHBOARD,
    component: CreatorsDashboardPage,
    label: 'Podcasts',
    icon: PodcastsIcon,
    allowedRoles: [UserRole.CREATOR]
  },
  // Podcasts
  {
    path: Pages.PODCASTS,
    component: PodcastsPage,
    label: 'Podcasts',
    icon: PodcastsIcon,
    allowedRoles: [UserRole.ADMIN]
  },
  // Hosts
  {
    path: Pages.HOSTS,
    component: HostsPage,
    label: 'Hosts',
    icon: FactoryIcon,
    allowedRoles: [UserRole.ADMIN]
  },
  // Producers
  {
    path: Pages.PRODUCERS,
    component: ProducersPage,
    label: 'Publishers',
    icon: ProducerIcon,
    allowedRoles: [UserRole.ADMIN]
  },
  // Artists
  {
    path: Pages.ARTISTS,
    component: ArtistsPage,
    label: 'Artists',
    icon: RecordVoiceOverIcon,
    allowedRoles: [UserRole.ADMIN]
  },
  // Users
  {
    path: Pages.USERS,
    component: UsersPage,
    label: 'Users Management',
    icon: PeopleIcon,
    allowedRoles: [UserRole.ADMIN]
  },
  // Suggestions
  {
    path: Pages.SUGGESTIONS,
    component: SuggestionsPage,
    label: 'Suggestions',
    icon: ThumbUpOffAltIcon,
    allowedRoles: [UserRole.ADMIN]
  },
  // Posts
  {
    path: Pages.POSTS,
    component: PostsPage,
    label: 'Blog',
    icon: NewspaperIcon,
    allowedRoles: [UserRole.ADMIN]
  },
  // Feedback
  {
    path: Pages.FEEDBACK,
    component: FeedbackPage,
    label: 'Feedback',
    icon: ForumIcon,
    allowedRoles: [UserRole.ADMIN]
  },
  // Logs
  {
    path: Pages.SERVER_LOGS,
    component: ServerLogsPage,
    label: 'Server Logs',
    icon: SubjectIcon,
    allowedRoles: [UserRole.ADMIN]
  }
];

const flatRoutes = (routes: RoutesType[]): IAppRoute[] =>
  routes.flatMap(route => (route.children?.length ? flatRoutes(route.children) : [route]));

const INTERNAL_ROUTES = [
  {
    path: Pages.PODCAST,
    component: PodcastPage
  },
  {
    path: Pages.EPISODE,
    component: EpisodePage
  }
] as IAppRoute[];

export const APP_ROUTES = flatRoutes(ROUTES).concat(INTERNAL_ROUTES) as AppRoutesType[];
