const calcMonthsPassed = (date1: Date, date2: Date) =>
  date1.getMonth() - date2.getMonth() + 12 * (date1.getFullYear() - date2.getFullYear());

const getWeekDayName = (date: string) =>
  new Date(date).toLocaleDateString('en-GB', { weekday: 'long' });

const getDateTime = (date: string) =>
  new Intl.DateTimeFormat('en-GB', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }).format(new Date(date));

export const calcDaysPassed = (date1: Date, date2: Date = new Date()) =>
  Math.round(Math.abs(date1.getTime() - date2.getTime()) / 864e5); // 1000 * 60 * 60 * 24

export const formatDate = (date: string, shortDate = false, breakAt = false) => {
  if (shortDate) {
    const seconds = Math.round((new Date().getTime() - new Date(date).getTime()) / 1000);
    const minutes = Math.ceil(seconds / 60);

    if (minutes === 0) return 'A few sec ago';

    if (minutes < 50) return `${minutes} min ago`;

    const hours = Math.ceil(minutes / 60);
    if (hours < 12) return `${hours} hr ago`;
  }

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  if (new Date(date).toLocaleDateString() === today.toLocaleDateString()) {
    return `Today ${breakAt ? '<br />' : ''}at ${getDateTime(date)}`;
  }

  if (new Date(date).toLocaleDateString() === yesterday.toLocaleDateString()) {
    return `Yesterday ${breakAt ? '<br />' : ''}at ${getDateTime(date)}`;
  }

  const passedDays = calcDaysPassed(new Date(date));
  if (passedDays <= 7) return getWeekDayName(date);

  if (passedDays % 7 === 0 && passedDays <= 28)
    return `${passedDays / 7} week${passedDays === 7 ? '' : 's'} ago`;

  if (passedDays <= 29) return `${passedDays} days ago`;

  const paddedMonths = calcMonthsPassed(new Date(date), new Date());

  if (paddedMonths > 1 && paddedMonths < 12) return `${paddedMonths} months ago`;

  return new Date(date).toLocaleDateString('en-GB');
};

export const formatDateTime = (date: string) =>
  new Intl.DateTimeFormat('en-GB', {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  }).format(new Date(date));

export const formatTime = (time: number) =>
  [
    Math.floor(time / 3600)
      .toString()
      .padStart(2, '0'),
    Math.floor((time % 3600) / 60)
      .toString()
      .padStart(2, '0'),
    Math.round(time % 60)
      .toString()
      .padStart(2, '0')
  ].join(':');

export const convertTimeStringToSeconds = (time: string) => {
  return time
    .split(':')
    .map(item => parseInt(item, 10))
    .reduce((acc, t) => 60 * acc + +t);
};

export const formatDuration = (duration: number, showSecs = false) => {
  let totalSeconds = duration;

  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return `${hours ? `${hours} hr` : ''} ${minutes ? `${minutes} min` : ''} 
  ${seconds && showSecs ? `${seconds} sec` : ''}`;
};

export const getMinsLeft = (secs: number) => {
  return secs < 60 ? '< 1' : formatDuration(secs);
};
