import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { showStandAloneAlert } from 'contexts/AlertContext';
import { closeDialog } from 'contexts/DialogContext';
import { GetAllResponse, GetResponse, Params } from 'interfaces/general';
import { RootState } from 'redux/store';
import { isRecord } from 'utils/helpers';
import { queryTags, TagTypes } from 'utils/queryTags';
import { REDUCER_PATH, RTKBuilder } from './types';

export const onSuccessQuery = (doc: string, verb: string) => {
  closeDialog();
  showStandAloneAlert({
    status: 'success',
    message: `${doc} ${verb} successfully.`
  });
};

export type DocDeleteArgType = {
  docId: string;
  tag: TagTypes;
  nestedDoc?: TagTypes;
  nestedDocId?: string;
};

export interface IOption {
  _id: string;
  [key: string]: unknown;
}

export const baseApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: Object.values(TagTypes),
  endpoints: builder => ({
    deleteDocument: builder.mutation<null, DocDeleteArgType>({
      query: ({ docId, tag, nestedDocId, nestedDoc }) => ({
        url: `/${tag}/${docId}/${nestedDoc && nestedDocId ? `${nestedDoc}/${nestedDocId}/` : ''}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_, __, arg) => queryTags[arg.tag].list(),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          onSuccessQuery('Document', 'deleted');
        } catch (__) {}
      }
    }),
    getDocuments: builder.query<GetAllResponse<IOption>, Params & { tag: TagTypes }>({
      query: ({ tag, ...params }) => {
        return {
          url: `/${tag}`,
          params: { select: 'name,username,title', ...params }
        };
      },
      providesTags: (_, __, { tag }) => queryTags[tag].list()
    })
  })
});

export class CrudController {
  private builder: RTKBuilder;

  private basePath: string;

  private tag: TagTypes;

  private title: string;

  constructor(builder: RTKBuilder, basePath: string, tag: TagTypes, title = 'Document') {
    this.builder = builder;
    this.basePath = basePath;
    this.tag = tag;
    this.title = title;
  }

  private static getFormDataValue = (val: unknown) => {
    if (typeof val === 'string') return val;

    if (val instanceof FileList) return val[0];

    if (val instanceof File) return val;

    return JSON.stringify(val);
  };

  static generateFormData = (data: Record<string, unknown>) => {
    const formData = new FormData();
    Object.entries(data).forEach(([k, v]) =>
      formData.append(k, CrudController.getFormDataValue(v))
    );
    return formData;
  };

  createDocumentMutation = <T>(multipart?: boolean, tag?: TagTypes) =>
    this.builder.mutation<null, T>({
      query: data => ({
        url: this.basePath,
        method: 'POST',
        body: multipart && isRecord(data) ? CrudController.generateFormData(data) : data
      }),
      invalidatesTags: [...queryTags[this.tag].list(), ...(tag ? queryTags[tag].list() : [])],
      onQueryStarted: async (d, { queryFulfilled }) => {
        try {
          await queryFulfilled;

          if (isRecord(d) && d.customMessage && typeof d.customMessage === 'string') {
            showStandAloneAlert({
              status: 'success',
              message: d.customMessage
            });
          } else onSuccessQuery(this.title, 'created');
        } catch (__) {}
      }
    });

  updateDocumentMutation = <T extends { _id: string }>(multipart = false) =>
    this.builder.mutation<null, T>({
      query: ({ _id, ...data }) => ({
        url: `${this.basePath}/${_id}`,
        method: 'PATCH',
        body: multipart ? CrudController.generateFormData(data) : data
      }),
      invalidatesTags: (__, _, arg) => [
        ...queryTags[this.tag].list(),
        ...queryTags[this.tag].single(arg._id)
      ],
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          onSuccessQuery(this.title, 'updated');
        } catch (__) {}
      }
    });

  getDocumentsQuery = <T>(path = '') =>
    this.builder.query<GetAllResponse<T>, Params>({
      query: params => {
        return {
          url: `${this.basePath}/${path}`,
          params
        };
      },
      providesTags: queryTags[this.tag].list()
    });

  getDocumentQuery = <T>(initialParams?: Params) =>
    this.builder.query<GetResponse<T>, string>({
      query: id => {
        return {
          url: `${this.basePath}/${id}`,
          params: initialParams
        };
      },
      providesTags: (_, __, arg) => queryTags[this.tag].single(arg)
    });
}

export const { useDeleteDocumentMutation, useGetDocumentsQuery } = baseApi;
