import {
  createContext,
  ReactNode,
  FC,
  useState,
  useMemo,
  PropsWithChildren,
  useEffect
} from 'react';
import Dialog from '@mui/material/Dialog';
import { Breakpoint } from '@mui/material/styles';

import DialogTitle from 'components/DialogTitle';

interface DialogProps {
  content: ReactNode;
  open: boolean;
  title: string;
  icon?: ReactNode;
  fullScreen?: boolean;
  fullWidth?: boolean;
  maxWidth?: Breakpoint | false;
  onClose?: VoidFunction;
  onExited?: VoidFunction;
  titleClass?: string;
}

type DialogOption = Omit<DialogProps, 'open'>;

/**
 * The first element is a function for opening a new dialog.
 * And the second element is a function for closing the dialog
 *
 * @example [openDialog, closeDialog]
 */
type DialogContextType = readonly [(opt: DialogOption) => void, VoidFunction];

// eslint-disable-next-line @typescript-eslint/no-empty-function
const CTX_INITIAL_VALUE = [() => {}, () => {}] as const;

export const DialogContext = createContext<DialogContextType>(CTX_INITIAL_VALUE);

// eslint-disable-next-line import/no-mutable-exports
let closeDialog: VoidFunction;

const DialogProvider: FC<PropsWithChildren> = ({ children }) => {
  // List of all dialogs props
  const [dialogs, setDialogs] = useState<DialogProps[]>([]);

  const createDialog = (opt: DialogOption) => {
    setDialogs(dgs => [...dgs, { ...opt, open: true }]);
  };

  const handleClose = () => {
    // Close the last dialog that was opened !
    setDialogs(dgs => {
      const latestDialog = dgs.pop();
      if (!latestDialog) return dgs;

      if (latestDialog.onClose) latestDialog.onClose();
      return [...dgs, { ...latestDialog, open: false }];
    });
  };

  const handleKill = (onExited?: VoidFunction) => {
    if (onExited) onExited();
    // Remove the last dialog item
    setDialogs(dgs => dgs.slice(0, -1));
  };

  useEffect(() => {
    closeDialog = handleClose;
  }, []);

  const contextValue = useMemo(() => [createDialog, handleClose] as const, []);

  return (
    <DialogContext.Provider value={contextValue}>
      {children}
      {dialogs.map(
        ({ onClose: _, content, title, onExited, maxWidth = 'sm', titleClass, icon, ...rest }) => (
          <Dialog
            key={title}
            onClose={handleClose}
            TransitionProps={{ onExited: handleKill.bind(null, onExited) }}
            maxWidth={maxWidth}
            {...rest}
          >
            {title && (
              <DialogTitle title={title} className={titleClass} icon={icon} onClose={handleClose} />
            )}
            {content}
          </Dialog>
        )
      )}
    </DialogContext.Provider>
  );
};

export { closeDialog };

export default DialogProvider;
