import { FC } from 'react';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CircleIcon from '@mui/icons-material/Circle';
import Link from '@mui/material/Link';

import CustomAvatar from 'components/CustomAvatar';
import MenuButton from 'components/MenuButton';
import {
  useClearMyNotificationsMutation,
  useGetMyNotificationsQuery,
  useUpdateMyNotificationMutation
} from 'redux/api/usersApi';
import {
  DashboardNotificationType,
  INotification,
  NotificationType,
  UserRole
} from 'interfaces/userInterface';
import { DashboardTabValue, FeedbackTabs, Pages, PodcastTabValue } from 'interfaces/general';
import { formatDate } from 'utils/date';
import { Color } from 'theme/muiTheme';
import { useSocket } from 'hooks/useSocket';
import { useStyles } from './styles/notification';

type NotificationProp = { notification: INotification };

const getAction = (type: DashboardNotificationType | NotificationType) => {
  switch (type) {
    case DashboardNotificationType.FLAG:
      return 'flagged';
    case DashboardNotificationType.NEW_BUG:
      return 'reported new bug';
    case DashboardNotificationType.NEW_FEATURE:
      return 'requested new feature';
    case DashboardNotificationType.INCORRECT_SPLIT:
      return 'Incorrect Split !';
    default:
      return '';
  }
};

const NotificationAction: FC<NotificationProp> = ({ notification }) => {
  const [updateMutation] = useUpdateMyNotificationMutation();

  return (
    <Tooltip title={notification.seen ? 'Mark as unread' : 'Mark as read'}>
      <IconButton
        onClick={() => updateMutation({ id: notification._id, seen: !notification.seen })}
      >
        <CircleIcon sx={{ fontSize: 10 }} />
      </IconButton>
    </Tooltip>
  );
};

const UserNotification: FC<{ notification: INotification; userRole: UserRole }> = ({
  notification,
  userRole
}) => {
  const { flagger, clip, episode, podcast, feedback, type } = notification;

  const date = (
    <Stack
      component="span"
      ml="auto"
      display="inline-flex"
      color={Color.NTR_200}
      fontWeight="400"
      fontSize="0.8em"
    >
      {formatDate(notification.date, true)}
    </Stack>
  );

  return (
    <Stack direction="row" gap="14px" alignItems="center" py="4px" width="100%">
      {flagger && (
        <>
          <CustomAvatar
            key={flagger.avatar}
            src={flagger.avatar}
            alt={flagger.username}
            username={flagger.username}
          />

          <Typography variant="subtitle1" flex="1" display="flex" gap="10px" alignItems="center">
            <Link
              sx={{ textDecoration: 'none' }}
              href={`${process.env.REACT_APP_WEBSITE_HOST}/fans/${flagger.username}`}
              target="_blank"
            >
              <strong>{flagger.name}&nbsp;</strong>
            </Link>

            <strong>{getAction(notification.type)}</strong>

            <Link
              sx={{ color: 'inherit' }}
              href={`${userRole === UserRole.CREATOR ? Pages.CREATOR_DASHBOARD : Pages.HOME}?tab=${
                clip ? DashboardTabValue.CLIPS : DashboardTabValue.BOOSTS
              }`}
            >
              {clip ? 'clip' : 'boost'}
            </Link>

            {date}
          </Typography>
        </>
      )}

      {feedback && (
        <>
          <CustomAvatar
            key={feedback.user.avatar}
            src={feedback.user.avatar}
            alt={feedback.user.username}
            username={feedback.user.username}
          />

          <Typography variant="subtitle1" flex="1" display="flex" gap="10px" alignItems="center">
            <Link
              sx={{ textDecoration: 'none' }}
              target="_blank"
              href={`${process.env.REACT_APP_WEBSITE_HOST}/fans/${feedback.user.username}`}
            >
              <strong>{feedback.user.name}&nbsp;</strong>
            </Link>

            <Link
              sx={{ color: 'inherit' }}
              href={`${Pages.FEEDBACK}?tab=${
                type === DashboardNotificationType.NEW_BUG
                  ? FeedbackTabs.Report
                  : FeedbackTabs.Suggest
              }`}
            >
              <strong>{getAction(notification.type)}</strong>
            </Link>

            {date}
          </Typography>
        </>
      )}

      {episode && (
        <>
          <CustomAvatar
            key={episode.image}
            src={episode.image || episode.podcast.image}
            alt={episode.title}
            variant="square"
            path={`${episode.podcast.slug}/${episode._id}`}
          />

          <Typography variant="subtitle1" flex="1" display="flex" gap="10px" alignItems="center">
            <Link
              sx={{ color: 'inherit' }}
              href={`/episodes/${episode._id}?tab=${PodcastTabValue.V4V}`}
            >
              <strong>{getAction(notification.type)}</strong>
            </Link>

            {date}
          </Typography>
        </>
      )}
      {podcast && (
        <>
          <CustomAvatar
            key={podcast.image}
            src={podcast.image}
            alt={podcast.title}
            variant="square"
            path={podcast.slug}
          />

          <Typography variant="subtitle1" flex="1" display="flex" gap="10px" alignItems="center">
            <Link
              sx={{ color: 'inherit' }}
              href={`/podcasts/${podcast._id}?tab=${PodcastTabValue.V4V}`}
            >
              <strong>{getAction(notification.type)}</strong>
            </Link>

            {date}
          </Typography>
        </>
      )}

      <NotificationAction notification={notification} />
    </Stack>
  );
};

const UserNotifications: FC<{ userRole: UserRole }> = ({ userRole }) => {
  useSocket();
  const { classes } = useStyles();
  const { data } = useGetMyNotificationsQuery(null);
  const [clearNotifications] = useClearMyNotificationsMutation();
  const notifications = data?.data.notifications;

  if (!notifications) return null;

  return (
    <MenuButton
      className={classes.root}
      buttonProps={{ sx: { paddingX: 0 } }}
      buttonNode={
        <Badge badgeContent={notifications.length} color="primary">
          <NotificationsNoneIcon color="primary" />
        </Badge>
      }
    >
      <Divider />
      <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
        <Typography fontSize="12px" pl="2rem" m="18px 0 10px" color={Color.NTR_200}>
          {notifications.length ? 'Notifications ' : 'No new notifications'}
        </Typography>

        <Button
          disabled={!notifications.length}
          onClick={() => clearNotifications(null)}
          className={classes.clearBtn}
        >
          Clear All
        </Button>
      </Stack>
      {notifications.map(nt => (
        <MenuItem key={nt._id}>
          <UserNotification notification={nt} userRole={userRole} />
        </MenuItem>
      ))}
    </MenuButton>
  );
};

export default UserNotifications;
