import { makeStyles } from 'tss-react/mui';

import { Color } from 'theme/muiTheme';

export const useStyles = makeStyles()(() => ({
  root: {
    padding: '2rem 1.6rem',
    minWidth: '42rem',
    maxHeight: '75vh',
    overflowY: 'auto',

    '& > li:not(:last-child)': {
      marginBottom: 10,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'rgba(0, 0, 0, 0.1)'
    },

    '& > li': {
      '& button': {
        padding: 0,
        width: 24,
        height: 24,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'transparent'
      },

      '&:hover button': {
        borderColor: Color.NTR_500
      }
    }
  },
  clearBtn: {
    height: 30,
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
    color: Color.NTR_200
  }
}));
