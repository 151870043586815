import { useContext, useEffect } from 'react';

import { userApi } from 'redux/api/usersApi';
import { SocketContext } from 'contexts/SocketContext';
import { SocketEvents } from 'utils/constants';
import { queryTags } from 'utils/queryTags';
import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';

export const useSocket = () => {
  const socket = useContext(SocketContext);
  const dispatch = useAppDispatch();
  const { token } = useAppSelector(state => state.auth);

  useEffect(() => {
    if (socket && token) {
      socket.connect();

      socket.once('connect', () => {
        // console.log('Socket connected, authorizing....');
        socket.emit(SocketEvents.AUTH, token);
      });

      socket.on(SocketEvents.NOTIFICATION, () => {
        // console.log('Received Notification !!!');
        dispatch(userApi.util.invalidateTags(queryTags.notifications.list()));
      });
    }

    return () => {
      socket?.off();
      socket?.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, token]);

  return socket;
};
