import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Pages } from 'interfaces/general';
import { UserRole } from 'interfaces/userInterface';
import { useAppSelector } from 'hooks/useAppSelector';

interface ProtectRouteProps {
  allowedRoles?: UserRole[];
  navigate?: boolean;
}

const ProtectRoute: FC<ProtectRouteProps> = ({ allowedRoles, navigate }) => {
  const location = useLocation();
  const { user } = useAppSelector(state => state.auth);

  if (!user && !navigate) {
    return <Navigate to={Pages.LOGIN} state={{ from: location }} replace />;
  }

  if (navigate && user) {
    return (
      <Navigate
        to={user.role === UserRole.ADMIN ? Pages.HOME : Pages.CREATOR_DASHBOARD}
        state={{ from: location }}
        replace
      />
    );
  }

  if (user && allowedRoles) {
    return allowedRoles.includes(user.role) ? (
      <Outlet />
    ) : (
      <Navigate to={Pages.UNAUTHORIZED} state={{ from: location }} replace />
    );
  }

  return <Outlet />;
};

export default ProtectRoute;
