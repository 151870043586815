import type { WaitlistStatus } from './userInterface';

export interface MongoDoc {
  _id: string;
  createdAt: string;
}

export interface Params {
  page?: number;
  limit?: number;
  select?: string;
  sort?: string;
  [key: string]: unknown;
}

export type ParamsFunction = (params: Params) => void;

export type OptionType = { label: string; value: string | number };

export enum CurrencyUnit {
  USD = 'USD',
  GBP = 'GBP',
  AUD = 'AUD',
  EUR = 'EUR',
  SEK = 'SEK'
}

export interface ExchangeRate {
  rate: number;
  currency: CurrencyUnit;
  updatedAt: string;
}

export enum Documents {
  clips,
  boosts,
  episodes,
  podcasts,
  users,
  claims,
  activities,
  suggestions,
  feedbacks,
  changelogs,
  posts,
  notifications,
  reviews,
  logs,
  events,
  hosts,
  fans,
  reports,
  producers,
  artists
}

export enum Document {
  clip,
  boost,
  episode,
  podcast,
  user,
  claim,
  post,
  activity,
  host,
  event,
  artist,
  producer
}

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export type DocKeys = keyof typeof Documents;

export interface GetAllResponse<T> {
  totalDocs: number;
  results: number;
  searchTerm?: string;
  data: {
    [key in DocKeys]: T[];
  } & {
    clipCount?: number;
    boostCount?: number;
    newUserCount?: number;
    stats?: Record<WaitlistStatus, number>;
    currency?: CurrencyUnit;
    meta?: { totalTransferredSats: number; totalVerified: number; totalVirtualSats: number };
  };
}

export interface GetResponse<T> {
  data: {
    [key in keyof typeof Document]: T;
  };
}

export enum PodcastTabValue {
  BASIC = 'basic',
  META_DATA = 'metadata',
  CHAPTERS = 'chapters',
  CLIPS = 'clips',
  TRANSCRIPT = 'transcript',
  V4V = 'value4value',
  LIVE_SHOW = 'live-show',
  PODROLL = 'podroll',
  EVENTS = 'events',
  BLOG = 'blog',
  TASKS = 'tasks'
}

export enum DashboardTabValue {
  V4V = 'value4value',
  GAMIFICATION = 'gamification',
  PODCASTS = 'podcasts',
  PUBLISHER = 'publisher',
  REPORTS = 'reports',
  BOOSTS = 'boosts',
  CLIPS = 'clips',
  CLAIM = 'claim'
}

export enum UserTabValue {
  USERS = 'users',
  WAIT_LIST = 'waiting-list'
}

export enum FeedbackTabs {
  RoadMap = 'roadmap',
  ChangeLog = 'changelog',
  Suggest = 'suggest',
  Report = 'report'
}

export enum Pages {
  HOME = '/home',
  PASSWORD_RESET = '/password-reset/:token',
  CREATOR_DASHBOARD = '/creators-dashboard',
  PODCASTS = '/podcasts',
  PRODUCERS = '/publishers',
  ARTISTS = '/artists',
  EPISODES = '/episodes',
  PODCAST = '/podcasts/:podcastId',
  EPISODE = '/episodes/:episodeId',
  USERS = '/users',
  SUGGESTIONS = '/suggestions',
  SERVER_LOGS = '/server-logs',
  LOGIN = '/login',
  FEEDBACK = '/feedback',
  POSTS = '/posts',
  HOSTS = '/hosts',
  UNAUTHORIZED = '/unauthorized'
}

export enum PodModel {
  Podcast = 'Podcast',
  Episode = 'Episode'
}

export enum AllActions {
  ADD_PERSONS = 'Add Hosts & Cast Members',
  ENABLE_MONETISATION = 'Turn On Monetisation',
  SET_VALUE = 'Set Play Value'
}
