import { FC, Suspense } from 'react';
import { unstable_HistoryRouter as HistoryRouter, Routes, Route, Navigate } from 'react-router-dom';

import { APP_ROUTES } from 'routes';
import { Pages } from 'interfaces/general';
import { history } from 'utils/history';
import { lazyPreload } from 'utils/lazyPreload';
import DialogProvider from 'contexts/DialogContext';
import MainLayout from 'layouts/main';
import ProtectRoute from 'components/ProtectRoute';
import FullScreenLoader from 'components/FullScreenLoader';

const LoginPage = lazyPreload(() => import('pages/login'));
const PasswordResetPage = lazyPreload(() => import('pages/password-reset'));
const NotFoundPage = lazyPreload(() => import('pages/404'));
const UnauthorizedPage = lazyPreload(() => import('pages/403'));

const App: FC = () => {
  return (
    <HistoryRouter history={history}>
      <DialogProvider>
        <Suspense fallback={<FullScreenLoader />}>
          <Routes>
            <Route path="/" element={<ProtectRoute navigate />}>
              <Route index element={<Navigate to={Pages.LOGIN} replace />} />
              <Route path={Pages.LOGIN} element={<LoginPage />} />
              <Route path={Pages.PASSWORD_RESET} element={<PasswordResetPage />} />
            </Route>

            <Route element={<MainLayout />}>
              {APP_ROUTES.map(({ path, component: Component, allowedRoles }) => (
                <Route key={path} element={<ProtectRoute allowedRoles={allowedRoles} />}>
                  <Route path={path} element={<Component />} />
                </Route>
              ))}
            </Route>

            <Route path={Pages.UNAUTHORIZED} element={<UnauthorizedPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </DialogProvider>
    </HistoryRouter>
  );
};

export default App;
