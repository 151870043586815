export enum TagTypes {
  USERS = 'users',
  NOTIFICATIONS = 'notifications',
  WAIT_LIST = 'waitlists',
  PODCASTS = 'podcasts',
  EPISODES = 'episodes',
  CHANGELOGS = 'changelogs',
  FEEDBACKS = 'feedbacks',
  HOSTS = 'hosts',
  SUGGESTIONS = 'suggestions',
  CLAIMS = 'claims',
  CLIPS = 'clips',
  BOOSTS = 'boosts',
  POSTS = 'posts',
  EVENTS = 'events',
  PRODUCERS = 'producers',
  ARTISTS = 'artists',
  RATES = 'rates',
  REPORTS = 'reports',
  LOGS = 'logs',
  REVIEWS = 'reviews',
  SETTINGS = 'settings'
}

type QueryKeys = {
  list: () => [{ type: TagTypes; id: 'list' }];
  single: (id: string) => [{ type: TagTypes; id: string }];
};

const getQueryKeys = (key: TagTypes): QueryKeys => ({
  list: () => [{ type: key, id: 'list' }],
  single: (id: string) => [{ type: key, id }]
});

export const queryTags = Object.values(TagTypes).reduce(
  (acc, tag) => ({ ...acc, [tag]: getQueryKeys(tag) }),
  {} as Record<TagTypes, QueryKeys>
);
