import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Anchor from '@mui/material/Link';

import CustomAvatar from 'components/CustomAvatar';

interface SidebarListItemProps {
  label: string;
  path?: string;
  icon: React.ElementType | string;
  sidebarExpanded: boolean;
  nested?: boolean;
  externalLink?: boolean;
  pl?: number;
  onClick?: VoidFunction;
}

const SidebarListItem: FC<SidebarListItemProps> = ({
  label,
  path,
  icon: Icon,
  sidebarExpanded,
  nested,
  onClick,
  externalLink,
  pl
}) => {
  const { pathname } = useLocation();

  return (
    <ListItem disablePadding className={pathname === path ? 'active' : ''}>
      <ListItemButton
        sx={{
          justifyContent: sidebarExpanded ? 'initial' : 'center'
        }}
        {...(path
          ? externalLink
            ? { href: path, component: Anchor, target: '_blank' }
            : { to: path, component: Link }
          : {})}
        onClick={onClick}
      >
        <ListItemIcon
          sx={{
            mr: sidebarExpanded ? 3 : 'auto',
            justifyContent: 'center',
            pl: nested && sidebarExpanded ? pl || 2 : 'initial',
            '& svg': {
              width: 22,
              height: 22
            }
          }}
        >
          {typeof Icon === 'string' ? (
            <CustomAvatar
              key={Icon}
              src={Icon}
              variant="square"
              outline={false}
              width={25}
              height={25}
            />
          ) : (
            <Icon />
          )}
        </ListItemIcon>
        <ListItemText primary={label} sx={{ opacity: sidebarExpanded ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
};

export default SidebarListItem;
