import type { IEpisode, PopulatedClip, PopulatedBoost } from 'interfaces/episodeInterface';
import type { IPodcast } from 'interfaces/podcastInterface';
import { calcDaysPassed } from './date';

export const getNestedProperty = (obj: Record<string, unknown> | string, prop: string): string => {
  if (!prop || typeof obj === 'string') return obj as string;

  const properties = prop.split('.');

  return getNestedProperty(obj[properties.shift() as string] as string, properties.join('.'));
};

export const isRecord = (val: unknown): val is Record<string, unknown> =>
  val !== null && typeof val === 'object';

export const isPodcast = (item: IPodcast | IEpisode): item is IPodcast =>
  (item as IPodcast).feedUrl !== undefined;

export const isClip = (item: PopulatedClip | PopulatedBoost): item is PopulatedClip =>
  (item as PopulatedClip).duration !== undefined;

export const formatNumber = (num?: string | number): string => {
  if ((!num && num !== 0) || !Number.isFinite(+num)) return '---';

  return new Intl.NumberFormat('en-GB').format(+num);
};

export const isNew = (date: string) => {
  const daysPassed = calcDaysPassed(new Date(date));
  return daysPassed < 7;
};

export const isTruthy = (val: string | boolean) => {
  if (typeof val === 'boolean') return val === true;

  return val === 'true' || val === 'yes';
};

export const capitalize = (str: string) =>
  str?.length > 0 ? str[0].toUpperCase() + str.slice(1) : '';

export const getDurationLabel = (seconds: number) => {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;

  return `${mins ? `${mins}m ` : ''}${secs ? `${secs}s` : ''}`;
};

export const convertSatsTo = (sats: number, rate: number, decimal = 4): string =>
  (sats * (rate / 100000000)).toFixed(decimal);

export const convertCurrencyToSats = (currency: number, rate: number): string =>
  ((currency * 100000000) / rate).toFixed(4);

export const formatSats = (sats: number) => `§ ${formatNumber(Math.abs(sats))}`;

export const slugify = (str: string) =>
  str
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');

export const downloadXMLData = (data: string, filename: string) => {
  const blob = new Blob([data], { type: 'application/xml' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${filename}.xml`;
  a.click();
  URL.revokeObjectURL(url);
};
