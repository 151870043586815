import { CountryCodeType } from 'utils/constants';
import { MongoDoc } from './general';
import { IPodcastPerson } from './podcastInterface';

export enum HostTag {
  ALTERNATE_ENCLOSURE = 'Alternate Enclosure',
  BLOCK = 'Block',
  BOOSTAGRAMS = 'Boostagrams',
  CHAPTERS = 'Chapters',
  CHAT = 'Chat',
  EPISODE = 'Episode',
  FUNDING = 'Funding',
  GUID = 'GUID',
  IMAGES = 'Images',
  LICENSE = 'License',
  LIVE = 'Live',
  LIVE_BOOSTS = 'Live Boosts',
  LOCATION = 'Location',
  LOCKED = 'Locked',
  MEDIUM = 'Medium',
  OP3 = 'OP3',
  PERSON = 'Person',
  PODPING = 'Podping',
  PODROLL = 'Podroll',
  PUBLISHER = 'Publisher',
  SAT_STREAMING = 'Sat Streaming',
  SEARCH = 'Search',
  SEASON = 'Season',
  SN_NETWORK = 'Sn-Network',
  SOCIAL_INTERACT = 'Social Interact',
  SOUNDBITE = 'Soundbite',
  SYNCHRONIZE = 'Synchronize',
  TRAILER = 'Trailer',
  TRANSCRIPT = 'Transcript',
  TXT = 'Txt',
  UPDATE_FREQUENCY = 'Update Frequency',
  VALUE = 'Value',
  VALUE_TIME_SPLIT = 'Value Time Split',
  VIDEO = 'Video',
  WALLET_SWITCHING = 'Wallet Switching'
}

export interface IHost extends MongoDoc {
  name: string;
  rank: number;
  clicks: number;
  failedCount?: number;
  tagCount?: number;
  tags?: HostTag[];
  unsupportedTags?: HostTag[];
  categories?: string[];
  persons?: IPodcastPerson[];
  url?: string;
  description?: string;
  featured?: boolean;
  claimed?: boolean;
  podcastCount?: number;
  country?: CountryCodeType;
  email?: string;
  logo?: string;
  image?: string;
  PSPVerified?: boolean;
}

export type RecommendedHostsResponse = {
  currentHost?: IHost;
  hosts: IHost[];
  supportedTags: HostTag[];
  rssTags: Record<HostTag, number>;
};

export type HostFormType = Pick<
  IHost,
  | 'name'
  | 'country'
  | 'tags'
  | 'persons'
  | 'featured'
  | 'url'
  | 'email'
  | 'description'
  | 'categories'
> & { logo?: string | FileList; image?: string | FileList };
