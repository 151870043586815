import Cookies, { CookieAttributes } from 'js-cookie';

import { IUser } from 'interfaces/userInterface';

const DEFAULT_SET_OPTIONS: CookieAttributes = {
  path: '/',
  expires: 365,
  sameSite: 'Strict',
  secure: process.env.NODE_ENV === 'production'
};

export enum CookieKey {
  USER = 'user',
  TOKEN = 'accessToken'
}

export enum HintPageCookie {
  DASHBOARD = 'dashboard'
}

export class Storage {
  static deleteCookie = (key: CookieKey) => {
    Cookies.remove(key, { path: '/' });
  };

  static setCookie = (key: CookieKey | HintPageCookie, data: unknown) => {
    Cookies.set(key, JSON.stringify(data), DEFAULT_SET_OPTIONS);
  };

  static getCookie = <T>(key: CookieKey | HintPageCookie): T | undefined => {
    const data = Cookies.get(key);

    return data ? (JSON.parse(data) as T) : undefined;
  };

  static setCredentials = (user?: IUser, accessToken?: string) => {
    if (accessToken) {
      Storage.setCookie(CookieKey.TOKEN, accessToken);
    }

    if (user) {
      Storage.setCookie(CookieKey.USER, user);
    }
  };

  static removeCredentials = () => {
    Storage.deleteCookie(CookieKey.TOKEN);
    Storage.deleteCookie(CookieKey.USER);
  };

  static getAccessToken = () => Storage.getCookie<string>(CookieKey.TOKEN);

  static getCurrentUser = () => Storage.getCookie<IUser>(CookieKey.USER);

  static setHint = (key: HintPageCookie) => {
    const val = Storage.getCookie(key);
    Storage.setCookie(key, !val || val === 'true' || val === true ? 'false' : 'true');
  };

  static shouldShowHint = (key: HintPageCookie) => {
    const val = Storage.getCookie(key);
    return val !== 'false' && val !== false;
  };
}
