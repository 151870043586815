import { makeStyles } from 'tss-react/mui';

import { Color } from 'theme/muiTheme';

export const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.4rem 2.4rem',
    marginBottom: '2.4rem',
    minWidth: '50rem',
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backgroundColor: Color.WHITE,

    '&::after': {
      content: '""',
      display: 'block',
      height: '1px',
      position: 'absolute',
      bottom: 0,
      left: '2.4rem',
      right: '2.4rem',
      backgroundColor: Color.BORDER
    }
  },
  button: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem'
  }
}));
