import React, { createContext, FC, PropsWithChildren, useMemo, useState, useEffect } from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

interface SnackbarProps {
  open: boolean;
  message: string;
  status: AlertColor;
  duration?: number | null;
}
const defaultSnackbarProps: SnackbarProps = {
  open: false,
  message: '',
  status: 'info',
  duration: 3000
};

export type AlertOption = Omit<SnackbarProps, 'open'>;

/**
 * The first element is a function for opening a new alert.
 *
 * @example [openAlert]
 */
type AlertContextType = readonly [(opt: AlertOption) => void];
// eslint-disable-next-line @typescript-eslint/no-empty-function
const CTX_INITIAL_VALUE = [() => {}] as const;

export const AlertContext = createContext<AlertContextType>(CTX_INITIAL_VALUE);

// eslint-disable-next-line import/no-mutable-exports
let showStandAloneAlert: (opt: AlertOption) => void | undefined;

const AlertProvider: FC<PropsWithChildren> = ({ children }) => {
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>(defaultSnackbarProps);
  const { open, message, status, duration } = snackbarProps;

  const handleClose = (_?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarProps({ ...defaultSnackbarProps, ...snackbarProps, open: false });
  };

  const openAlert = (opt: AlertOption) => {
    setSnackbarProps({ ...opt, open: true });
  };

  useEffect(() => {
    showStandAloneAlert = openAlert;
  }, []);

  const contextValue: AlertContextType = useMemo(() => [openAlert] as const, []);

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          open={open}
          onClose={handleClose}
          autoHideDuration={duration !== null ? duration || 3000 : null}
        >
          <Alert onClose={handleClose} severity={status} sx={{ width: '100%' }}>
            <Typography variant="h6">{message} </Typography>
          </Alert>
        </Snackbar>
      </Stack>
    </AlertContext.Provider>
  );
};

export { showStandAloneAlert };

export default AlertProvider;
