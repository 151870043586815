import { createContext, FC, PropsWithChildren } from 'react';
import socketIOClient, { Socket } from 'socket.io-client';

const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL as string, {
  closeOnBeforeunload: false,
  autoConnect: false
});

export const SocketContext = createContext<Socket | null>(null);

const SocketProvider: FC<PropsWithChildren> = ({ children }) => {
  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

export default SocketProvider;
