import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

import { ILogin, IResetPasswordData, SuccessLoginResult } from 'interfaces/authInterface';
import { Pages } from 'interfaces/general';
import { UserRole } from 'interfaces/userInterface';
import { setCredentials, logout } from 'redux/slices/authSlice';
import { history } from 'utils/history';
import { showStandAloneAlert } from 'contexts/AlertContext';
import { baseApi } from './baseApi';

const ALLOWED_ROLES = [UserRole.ADMIN, UserRole.CREATOR];

const onSuccessLogin = (
  data: SuccessLoginResult,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>
) => {
  // Only admin users are allowed to login
  if (!ALLOWED_ROLES.includes(data.data?.user?.role)) {
    history.replace(Pages.UNAUTHORIZED);
    return;
  }

  dispatch(setCredentials({ user: data.data.user, token: data.token }));

  history.replace(data.data.user.role === UserRole.ADMIN ? Pages.HOME : Pages.CREATOR_DASHBOARD);
};

export const authApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    loginUser: builder.mutation<SuccessLoginResult, ILogin>({
      query: data => ({
        url: '/auth/login',
        method: 'POST',
        body: { ...data, podcasts: true }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          onSuccessLogin(data, dispatch);
        } catch (__) {}
      }
    }),
    forgotPassword: builder.mutation<null, { email: string }>({
      query: data => ({
        url: '/auth/forgot-password',
        method: 'POST',
        body: { ...data, dashboard: '1' }
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          showStandAloneAlert({
            status: 'success',
            message: 'Your password reset token has been sent successfully.'
          });
        } catch (__) {}
      }
    }),
    resetPassword: builder.mutation<SuccessLoginResult, IResetPasswordData>({
      query: ({ password, token }) => ({
        url: `/auth/reset-password/${token}`,
        method: 'PATCH',
        body: { password }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          onSuccessLogin(data, dispatch);
        } catch (__) {}
      }
    }),

    logoutUser: builder.mutation<void, void>({
      query: () => ({
        url: '/auth/logout/',
        method: 'GET'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(logout());
          history.replace(Pages.LOGIN);
        } catch (__) {}
      }
    })
  }),
  overrideExisting: false
});

export const {
  useLoginUserMutation,
  useLogoutUserMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation
} = authApi;
