import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import MaterialUIProvider from 'contexts/MuiContext';
import AlertProvider from 'contexts/AlertContext';
import SocketProvider from 'contexts/SocketContext';
import { store } from './redux/store';
import App from './App';

const muiCache = createCache({
  key: 'mui',
  prepend: true
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <CacheProvider value={muiCache}>
      <MaterialUIProvider>
        <SocketProvider>
          <AlertProvider>
            <App />
          </AlertProvider>
        </SocketProvider>
      </MaterialUIProvider>
    </CacheProvider>
  </Provider>
);
