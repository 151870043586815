import { MongoDoc } from './general';
import { BriefUser } from './userInterface';

export enum FeedbackPage {
  HOME = 'Home',
  SHOWS = 'Shows',
  TRAILERS = 'Trailers',
  PODCAST = 'Podcast',
  EPISODE = 'Episode',
  PROFILE = 'Profile',
  SETTINGS = 'Settings',
  FEEDBACK = 'Feedback',
  SEARCH = 'Search'
}

export enum FeedbackType {
  REPORT = 'report',
  SUGGEST = 'suggest'
}

export enum FeedbackStatus {
  UNDER_REVIEW = 'Under Review',
  PLANNED = 'Planned',
  IN_PROGRESS = 'In Progress',
  FIXED = 'Fixed',
  APPROVED = 'Approved'
}

export interface IFeedback extends MongoDoc {
  user: BriefUser;
  type: FeedbackType;
  title: string;
  status?: FeedbackStatus;
  description: string;
  score: number;
  viewerScore: 0 | 1;
  page?: FeedbackPage;
  image?: string;
}

export enum ChangelogTag {
  NEW = 'New',
  FIXED = 'Fixed',
  IMPROVED = 'Improved'
}

export interface IChangelog extends MongoDoc {
  title: string;
  description: string;
  publishedAt: string;
  tags?: ChangelogTag[];
  image?: string | FileList;
}

export type ChangelogFormType = Pick<
  IChangelog,
  'title' | 'description' | 'tags' | 'image' | 'publishedAt'
>;

export interface IReview {
  _id: string;
  payment: { amount: number };
  replies: number;
  message: string;
  user: BriefUser;
  createdAt: string;
}
