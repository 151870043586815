import { FC, ReactNode } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './styles';

interface DialogTitleProps {
  title: string;
  onClose: VoidFunction;
  className?: string;
  icon?: ReactNode;
}

const DialogTitle: FC<DialogTitleProps> = ({ title, className, icon, onClose }) => {
  const { classes } = useStyles();

  return (
    <div className={`${classes.root} ${className || ''}`}>
      {icon ? (
        <Stack direction="row" gap="8px" sx={{ '& > svg': { width: '24px' } }}>
          {icon}

          <Typography
            variant="h5"
            className="semi-bold"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </Stack>
      ) : (
        <Typography
          variant="h5"
          className="semi-bold"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}

      <IconButton aria-label="close" onClick={onClose} className={classes.button}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default DialogTitle;
