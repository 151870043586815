import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from '@reduxjs/toolkit/query/react';
import type {
  EndpointBuilder,
  MutationLifecycleApi
} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type { TagTypes } from 'utils/queryTags';

export const REDUCER_PATH = 'podfansAPI';

export type RTKMutationType<T> = MutationLifecycleApi<
  T,
  // eslint-disable-next-line @typescript-eslint/ban-types
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
  null,
  typeof REDUCER_PATH
>;

export type RTKBuilder = EndpointBuilder<
  // eslint-disable-next-line @typescript-eslint/ban-types
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
  TagTypes,
  typeof REDUCER_PATH
>;

export type PartialUpdateType<T> = Partial<T> & { _id: string; deletedItem?: unknown };

export type TransformerFunctionType<T> = (item: T) => T;
